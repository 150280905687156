import { useState, useEffect } from 'react'
import classNames from 'classnames'
import type { ReactNode } from 'react'

import type { Intent } from '~/services/theme-service'
import { getIntentSurface } from '~/services/theme-service'

interface BannerProps {
  id?: string
  title?: string
  children: ReactNode
  intent: Intent
  isRounded?: boolean
  className?: string
  dismissable?: boolean
}

const Banner = ({ id, title, isRounded = true, children, intent, className, dismissable = false }: BannerProps) => {
  const [isDismissed, setIsDismissed] = useState(false)

  useEffect(() => {
    if (dismissable && id) {
      const dismissed = localStorage.getItem(`banner-dismissed-${id}`)
      if (dismissed === 'true') {
        setIsDismissed(true)
      }
    }
  }, [id, dismissable])

  const handleDismiss = () => {
    if (id) {
      localStorage.setItem(`banner-dismissed-${id}`, 'true')
    }
    setIsDismissed(true)
  }

  if (isDismissed) return null

  return (
    <div
      className={classNames(
        'flex flex-row items-center justify-between p-5 leading-normal',
        { 'rounded-3xl': isRounded },
        getIntentSurface(intent),
        className
      )}>
      <div className="flex w-full flex-1 flex-col items-start justify-between space-y-1">
        {title && <p className="text-base font-bold">{title}</p>}
        {children && <div className="w-full text-base">{children}</div>}
      </div>
      {dismissable && (
        <button onClick={handleDismiss} aria-label="Dismiss banner" className="ml-4 grow-0 text-lg font-bold">
          &times;
        </button>
      )}
    </div>
  )
}

export default Banner
